import React, {useState} from "react"
import {
    Button,
    Grid, makeStyles, Modal,
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import {FormattedMessage, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout";
import Formulario from "../components/formulario"
import Seo from "../components/seo"

import {
    horasSR, inputsSREscuelas, costosSREscuelas, cerrado, feriados,
} from "../components/defaults"
import "../components/date.css"

const useModalStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        //width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "20%",
        left: `20%`,
        maxWidth: "50%"
    },
}));

const SantaRosaEscuelas = () => {
    const sectorACG = "Santa Rosa";
    const intl = useIntl();
    const fechasCerrado = cerrado[sectorACG];
    const [open, setOpen] = useState(false)
    const classes = useModalStyles() ;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({id: "sr.tituloEsc"})}
                 description={intl.formatMessage({id: "sr.info1Esc"})}
                 image={"/images/sr-escuelas.jpg"}
            />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({id: "sr.tituloEsc"})}
                    </Typography>
                    <Typography variant={"body2"}>
                        <FormattedMessage
                            id="sr.info1Esc"
                            values={{
                                a: text => <a href={"https://www.acguanacaste.ac.cr/images/documentos/ECOTURISMO_CASONA/atencion-centros-educativos-pn-santa-rosa.pdf"} target={"_blank"} rel={"noreferrer"}>{text}</a>,
                            }}
                        />


                    </Typography> <br />
                    <Typography variant={"body2"}>
                        <FormattedMessage
                            id="sr.escExo1"
                            values={{
                                a: text => <a href={"https://www.mideplan.go.cr/indice-desarrollo-social"} target={"_blank"} rel={"noreferrer"}>{text}</a>,
                            }}
                        />
                        &nbsp;<a href={"#"} onClick={handleClickOpen}>{intl.formatMessage({id: "sr.escExo2"})} </a>

                    </Typography> <br />
                    <Typography variant={"body2"}>
                        {intl.formatMessage({id: "sr.info2"})} <a href={"https://www.acguanacaste.ac.cr/turismo/sector-santa-rosa/sector-santa-rosa"} target={"_blank"} rel="noreferrer">{intl.formatMessage({id: "SR"})}</a> {intl.formatMessage({id: "sr.info3"})}
                    </Typography>
                </Grid>
            </Grid>
            <Formulario
                sector ={sectorACG}
                horas = {horasSR}
                inputFields =  {inputsSREscuelas}
                costos = {costosSREscuelas}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[2]}
                tipo={"escuela"}
                cerrado = {fechasCerrado}
                iva = {1}
                feriados = {feriados}
            />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={classes.paper}>
                    <Typography variant="h6" component="h4">Exoneraciones de tarifas de admisión a estudiantes de primaria y secundaria</Typography>
                    <Typography variant="body1">
                        Según el artículo 19. del  Decreto Ejecutivo Nº 34164-MINAE: <em>Tarifas por derechos de ingreso y otros servicios ofrecidos en las áreas silvestres protegidas bajo la administración del Sistema Nacional de Áreas de Conservación y degorativa, será la Dirección del Área de Conservación competente del ASP, la encargada de autorizar o no las exoneraciones previa justificación en resolución debidamente motivada.</em>
                    </Typography> <br />
                    <Typography variant="body1">
                        Para los casos de exoneración de las admisiones a centros educativos de primaria y secundaria se consideran los siguientes incisos:
                    </Typography> <br />

                    <Typography variant="body1">
                        <em>Inciso E) Los estudiantes de centros educativos (nivel de primaria y secundaria) ubicados en distritos con menor desarrollo social relativo, según el índice de desarrollo social establecido por el Ministerio de Planificación Nacional y Política Económica (MIDEPLAN); así como aquellos ubicados en las comunidades más cercanas a las áreas silvestres protegidas.</em>
                    </Typography> <br />

                    <Typography variant="body1">
                        Para solicitar una exoneración, asegúrese que su escuela o colegio cumple con los requisitos anteriores y escriba por correo electrónico una carta de a la Dirección del Área de Conservación Guancaste <a href={"mailto:direccion@acguanacaste.ac.cr"}>direccion@acguanacaste.ac.cr</a>. Incluya la justificación de su solicitud de exoneración, así como el nombre de su escuela o colegio, ubicación, cantidad de estudiantes y profesores que desean visitar. De ser aprobada su solicitud, haga la reservación en este formulario y adjúntela.
                    </Typography>

                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </div>

            </Modal>
        </Layout>
    )
}


export default SantaRosaEscuelas
